import React from "react";

export default function SvgInglesMuyIngles({ className }) {
  return (
    <svg
      width="209"
      height="22"
      viewBox="0 0 209 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M206.388 17.8897C206.302 17.8565 206.181 17.84 206.024 17.84H205.872V18.5349H206.115C206.262 18.5349 206.376 18.5057 206.458 18.4469C206.541 18.3883 206.582 18.2934 206.582 18.1626C206.582 18.0318 206.517 17.9407 206.388 17.8897ZM205.245 19.8135V17.4177C205.393 17.4177 205.616 17.4182 205.912 17.4191C206.208 17.4201 206.373 17.4217 206.405 17.4237C206.595 17.4373 206.752 17.4782 206.878 17.5466C207.09 17.6642 207.197 17.8548 207.197 18.1186C207.197 18.3199 207.141 18.4653 207.029 18.5547C206.916 18.6442 206.778 18.6977 206.614 18.7152C206.764 18.7468 206.878 18.7929 206.954 18.8537C207.094 18.9677 207.165 19.1468 207.165 19.3912V19.6055C207.165 19.6287 207.166 19.6521 207.17 19.6758C207.173 19.699 207.179 19.7227 207.187 19.7461L207.209 19.8135H206.611C206.591 19.7375 206.578 19.627 206.571 19.4821C206.564 19.3377 206.551 19.2399 206.532 19.1889C206.5 19.105 206.442 19.0462 206.356 19.0129C206.309 18.9933 206.237 18.9809 206.142 18.9749L206.004 18.9661H205.872V19.8135H205.245ZM204.922 17.3591C204.578 17.7109 204.406 18.1284 204.406 18.6114C204.406 19.1117 204.581 19.5371 204.931 19.8869C205.279 20.2368 205.699 20.4118 206.192 20.4118C206.682 20.4118 207.101 20.2358 207.449 19.8838C207.797 19.5299 207.971 19.1059 207.971 18.6114C207.971 18.1303 207.798 17.713 207.452 17.3591C207.102 16.9992 206.682 16.8194 206.192 16.8194C205.695 16.8194 205.272 16.9992 204.922 17.3591ZM207.704 20.1624C207.284 20.5692 206.78 20.7724 206.192 20.7724C205.584 20.7724 205.072 20.5644 204.655 20.1478C204.239 19.7313 204.031 19.2193 204.031 18.6114C204.031 17.9799 204.256 17.455 204.705 17.0365C205.128 16.6455 205.623 16.4499 206.192 16.4499C206.788 16.4499 207.297 16.6611 207.719 17.0834C208.141 17.5057 208.352 18.0148 208.352 18.6114C208.352 19.225 208.136 19.7423 207.704 20.1624Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5474 5.63183V16.4563H7.7933V14.268C7.50668 14.9978 7.03456 15.5675 6.37718 15.9776C5.71956 16.3879 4.96106 16.5931 4.10168 16.5931C2.8517 16.5931 1.85559 16.183 1.11334 15.3623C0.371097 14.5413 -0.000144958 13.3954 -0.000144958 11.9233V5.63183H2.73457V11.6108C2.73457 12.4705 2.95569 13.1381 3.39864 13.6135C3.84112 14.0892 4.44017 14.3266 5.19557 14.3266C5.98969 14.3266 6.62149 14.0727 7.09026 13.5647C7.55904 13.0566 7.7933 12.3275 7.7933 11.3762V5.63183H10.5474Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.579 6.70641C23.3213 7.5271 23.6925 8.67995 23.6925 10.165V16.4562H20.9578V10.4777C20.9578 9.61782 20.7362 8.95041 20.2937 8.47478C19.851 7.99939 19.2453 7.76169 18.4772 7.76169C17.696 7.76169 17.0709 8.01565 16.6023 8.52356C16.1333 9.03171 15.8991 9.7613 15.8991 10.7118V16.4562H13.145V5.63176H15.8991V7.80067C16.1852 7.07132 16.6576 6.50123 17.3149 6.09089C17.9723 5.68054 18.7311 5.4756 19.5905 5.4756C20.8407 5.4756 21.8368 5.88595 22.579 6.70641Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.2223 6.34501C46.8149 6.95073 47.1111 7.80729 47.1111 8.91447C47.1111 9.29229 47.0719 9.69594 46.9939 10.1259L45.8611 16.4564H42.5407L43.5758 10.5166C43.6147 10.2952 43.6343 10.0934 43.6343 9.91092C43.6343 9.36379 43.478 8.94053 43.1656 8.6409C42.8529 8.34127 42.4166 8.19158 41.857 8.19158C41.1927 8.19158 40.6231 8.40631 40.1478 8.83603C39.6724 9.26599 39.3695 9.8784 39.2394 10.6728L38.224 16.4564H34.8644L36.7784 5.55373H40.138L39.7866 7.50742C40.2162 6.86942 40.7729 6.36462 41.4564 5.99325C42.1401 5.62212 42.8986 5.43655 43.7321 5.43655C44.7995 5.43655 45.6297 5.73929 46.2223 6.34501Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.0293 9.05113C52.5084 9.52006 52.1764 10.1717 52.0332 11.0048C51.994 11.2394 51.9744 11.4611 51.9744 11.6696C51.9744 12.2947 52.1534 12.783 52.5115 13.135C52.8699 13.4865 53.3482 13.6625 53.9473 13.6625C54.6505 13.6625 55.269 13.4215 55.803 12.9394C56.3365 12.4578 56.675 11.8128 56.8187 11.0048C56.8577 10.7836 56.8773 10.5753 56.8773 10.3797C56.8773 9.74174 56.6946 9.24339 56.3303 8.88493C55.9655 8.52696 55.4836 8.34785 54.8848 8.34785C54.1686 8.34785 53.55 8.58243 53.0293 9.05113ZM49.5526 15.4208C48.8493 14.6394 48.4977 13.5647 48.4977 12.1969C48.4977 11.7019 48.5431 11.2071 48.6347 10.7119C48.83 9.63078 49.1976 8.6898 49.7381 7.88872C50.2784 7.08763 50.936 6.47546 51.711 6.05196C52.4855 5.62869 53.3092 5.41683 54.1818 5.41683C55.067 5.41683 55.7898 5.60598 56.3499 5.98356C56.9095 6.36163 57.2679 6.876 57.424 7.52715L57.7756 5.55385H61.0962L59.1819 16.3977C59.0125 17.4138 58.6644 18.3287 58.1371 19.1432C57.6097 19.9569 56.9095 20.605 56.0373 21.0873C55.1647 21.5689 54.149 21.8102 52.9904 21.8102C51.3756 21.8102 50.1318 21.4223 49.2595 20.6478C48.387 19.8723 47.9314 18.8208 47.8924 17.492H51.1932C51.2322 17.9346 51.4145 18.2799 51.7401 18.5274C52.0655 18.7749 52.5278 18.8988 53.1269 18.8988C53.817 18.8988 54.4062 18.7 54.8946 18.3028C55.383 17.9054 55.7052 17.2705 55.8616 16.3977L56.213 14.483C55.8224 15.1344 55.2917 15.6487 54.6211 16.0266C53.9504 16.4044 53.1919 16.5933 52.3456 16.5933C51.1865 16.5933 50.2556 16.2023 49.5526 15.4208Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.4046 1.99759L64.8655 16.4564H61.5061L64.0451 1.99759H67.4046Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.8777 2.75946L71.8188 4.77222L72.2096 2.50551L77.3269 0.239029L76.8777 2.75946ZM75.198 9.61796C75.198 9.0969 75.0352 8.70616 74.7098 8.44551C74.384 8.18509 73.974 8.05477 73.4792 8.05477C72.867 8.05477 72.3267 8.23722 71.858 8.60166C71.3892 8.96657 71.0634 9.50055 70.8815 10.2038H75.1394C75.1786 9.98264 75.198 9.78727 75.198 9.61796ZM77.3171 6.55996C78.1308 7.32207 78.5379 8.34125 78.5379 9.61796C78.5379 9.91759 78.5054 10.282 78.4404 10.712C78.388 11.0245 78.3037 11.3244 78.1865 11.6109H70.6273C70.6013 11.8715 70.5884 12.0473 70.5884 12.1384C70.5884 13.3238 71.1549 13.9163 72.2875 13.9163C72.7692 13.9163 73.199 13.7927 73.5767 13.5452C73.9544 13.2977 74.2279 12.9785 74.3971 12.5877H77.9324C77.5028 13.7731 76.7573 14.7368 75.6959 15.4795C74.6345 16.2218 73.3943 16.5932 71.9753 16.5932C70.5688 16.5932 69.4522 16.2022 68.6253 15.4209C67.7985 14.6392 67.3851 13.5584 67.3851 12.1774C67.3851 11.7606 67.4241 11.3177 67.5023 10.8488C67.6976 9.74159 68.1011 8.77766 68.7133 7.95696C69.3253 7.13627 70.0838 6.50807 70.9888 6.07166C71.8936 5.63501 72.8801 5.41692 73.948 5.41692C75.3801 5.41692 76.5031 5.79786 77.3171 6.55996Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.2063 6.44265C89.0135 7.12656 89.4627 8.04171 89.554 9.18786H86.2726C86.2595 8.73208 86.09 8.38056 85.7649 8.13282C85.4391 7.88556 84.9964 7.76169 84.4365 7.76169C84.0327 7.76169 83.7041 7.83989 83.4502 7.99604C83.1963 8.15219 83.0694 8.38056 83.0694 8.67995C83.0694 8.97982 83.2451 9.21417 83.5967 9.38323C83.9484 9.55278 84.4822 9.72878 85.1983 9.91076C85.9925 10.1327 86.6405 10.3441 87.1418 10.5459C87.6429 10.748 88.076 11.0538 88.4406 11.4644C88.8051 11.8745 88.9877 12.4118 88.9877 13.0761C88.9877 13.3497 88.9614 13.5972 88.9093 13.8186C88.7532 14.6393 88.2811 15.307 87.4934 15.8213C86.7055 16.3362 85.6475 16.5933 84.3194 16.5933C82.8478 16.5933 81.6662 16.2645 80.7743 15.6066C79.8821 14.9488 79.41 14.0209 79.3582 12.8222H82.6396C82.6006 13.2911 82.7632 13.6496 83.1279 13.8968C83.4923 14.1446 83.9806 14.268 84.5928 14.268C85.0353 14.268 85.3674 14.1802 85.589 14.0044C85.8103 13.8284 85.921 13.5972 85.921 13.3107C85.921 12.9853 85.7515 12.7409 85.413 12.578C85.0743 12.4152 84.5276 12.2296 83.7724 12.0213C83.017 11.8257 82.3922 11.6272 81.8973 11.4249C81.4023 11.2236 80.976 10.924 80.6179 10.5265C80.2596 10.1293 80.081 9.60491 80.081 8.95352C80.081 8.81028 80.1069 8.56947 80.159 8.23063C80.289 7.40993 80.7513 6.73582 81.5459 6.2083C82.3398 5.68054 83.4014 5.41678 84.7296 5.41678C86.2399 5.41678 87.3988 5.75897 88.2063 6.44265Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.123 6.69672C112.885 7.51096 113.266 8.66716 113.266 10.1648V16.4564H110.532V10.4776C110.532 9.644 110.32 9.00218 109.897 8.55285C109.473 8.10353 108.891 7.87874 108.149 7.87874C107.38 7.87874 106.768 8.12003 106.312 8.60164C105.857 9.08372 105.629 9.78724 105.629 10.712V16.4564H102.894V10.4776C102.894 9.644 102.682 9.00218 102.26 8.55285C101.836 8.10353 101.254 7.87874 100.511 7.87874C99.7429 7.87874 99.131 8.12003 98.6753 8.60164C98.2195 9.08372 97.9917 9.78724 97.9917 10.712V16.4564H95.2376V5.63187H97.9917V7.68337C98.278 6.98009 98.7308 6.43631 99.3492 6.05203C99.9674 5.66798 100.687 5.47548 101.508 5.47548C102.38 5.47548 103.139 5.68401 103.783 6.10081C104.428 6.51785 104.913 7.11664 105.238 7.89835C105.577 7.15585 106.081 6.56664 106.752 6.13022C107.423 5.69381 108.174 5.47548 109.008 5.47548C110.323 5.47548 111.361 5.88272 112.123 6.69672Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.138 5.63183V16.4563H123.384V14.268C123.097 14.9978 122.625 15.5675 121.968 15.9776C121.31 16.3879 120.552 16.5931 119.692 16.5931C118.442 16.5931 117.446 16.183 116.704 15.3623C115.961 14.5413 115.59 13.3954 115.59 11.9233V5.63183H118.325V11.6108C118.325 12.4705 118.546 13.1381 118.989 13.6135C119.431 14.0892 120.03 14.3266 120.786 14.3266C121.58 14.3266 122.212 14.0727 122.681 13.5647C123.149 13.0566 123.384 12.3275 123.384 11.3762V5.63183H126.138Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M130.591 5.63183L133.54 12.9784L136.274 5.63183H139.302L132.681 21.6146H129.634L132.075 16.1435L127.524 5.63183H130.591Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M160.7 6.34501C161.292 6.95073 161.588 7.80729 161.588 8.91447C161.588 9.29229 161.55 9.69594 161.471 10.1259L160.338 16.4564H157.017L158.053 10.5166C158.092 10.2952 158.112 10.0934 158.112 9.91092C158.112 9.36379 157.955 8.94053 157.643 8.6409C157.33 8.34127 156.894 8.19158 156.334 8.19158C155.67 8.19158 155.1 8.40631 154.625 8.83603C154.15 9.26599 153.847 9.8784 153.717 10.6728L152.701 16.4564H149.341L151.256 5.55373H154.615L154.264 7.50742C154.693 6.86942 155.25 6.36462 155.934 5.99325C156.617 5.62212 157.376 5.43655 158.209 5.43655C159.277 5.43655 160.107 5.73929 160.7 6.34501Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167.507 9.05113C166.985 9.52006 166.653 10.1717 166.51 11.0048C166.471 11.2394 166.452 11.4611 166.452 11.6696C166.452 12.2947 166.631 12.783 166.989 13.135C167.347 13.4865 167.825 13.6625 168.424 13.6625C169.128 13.6625 169.746 13.4215 170.28 12.9394C170.814 12.4578 171.152 11.8128 171.296 11.0048C171.335 10.7836 171.354 10.5753 171.354 10.3797C171.354 9.74174 171.172 9.24339 170.808 8.88493C170.443 8.52696 169.961 8.34785 169.362 8.34785C168.646 8.34785 168.027 8.58243 167.507 9.05113ZM164.03 15.4208C163.327 14.6394 162.975 13.5647 162.975 12.1969C162.975 11.7019 163.02 11.2071 163.112 10.7119C163.307 9.63078 163.675 8.6898 164.215 7.88872C164.755 7.08763 165.413 6.47546 166.188 6.05196C166.963 5.62869 167.786 5.41683 168.659 5.41683C169.544 5.41683 170.267 5.60598 170.827 5.98356C171.387 6.36163 171.745 6.876 171.901 7.52715L172.253 5.55385H175.573L173.659 16.3977C173.49 17.4138 173.141 18.3287 172.614 19.1432C172.087 19.9569 171.387 20.605 170.514 21.0873C169.642 21.5689 168.626 21.8102 167.467 21.8102C165.853 21.8102 164.609 21.4223 163.737 20.6478C162.864 19.8723 162.409 18.8208 162.37 17.492H165.67C165.71 17.9346 165.892 18.2799 166.217 18.5274C166.543 18.7749 167.005 18.8988 167.604 18.8988C168.294 18.8988 168.883 18.7 169.372 18.3028C169.86 17.9054 170.182 17.2705 170.339 16.3977L170.69 14.483C170.3 15.1344 169.769 15.6487 169.099 16.0266C168.428 16.4044 167.669 16.5933 166.823 16.5933C165.664 16.5933 164.733 16.2023 164.03 15.4208Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M181.882 1.99759L179.343 16.4564H175.983L178.522 1.99759H181.882Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.355 2.75946L186.296 4.77222L186.687 2.50551L191.804 0.239029L191.355 2.75946ZM189.675 9.61796C189.675 9.0969 189.512 8.70616 189.187 8.44551C188.861 8.18509 188.451 8.05477 187.956 8.05477C187.344 8.05477 186.804 8.23722 186.335 8.60166C185.866 8.96657 185.541 9.50055 185.359 10.2038H189.617C189.655 9.98264 189.675 9.78727 189.675 9.61796ZM191.794 6.55996C192.608 7.32207 193.015 8.34125 193.015 9.61796C193.015 9.91759 192.983 10.282 192.918 10.712C192.865 11.0245 192.781 11.3244 192.664 11.6109H185.104C185.078 11.8715 185.065 12.0473 185.065 12.1384C185.065 13.3238 185.632 13.9163 186.765 13.9163C187.246 13.9163 187.676 13.7927 188.054 13.5452C188.431 13.2977 188.705 12.9785 188.874 12.5877H192.41C191.98 13.7731 191.234 14.7368 190.173 15.4795C189.111 16.2218 187.871 16.5932 186.452 16.5932C185.046 16.5932 183.929 16.2022 183.102 15.4209C182.275 14.6392 181.862 13.5584 181.862 12.1774C181.862 11.7606 181.901 11.3177 181.979 10.8488C182.175 9.74159 182.578 8.77766 183.19 7.95696C183.802 7.13627 184.561 6.50807 185.466 6.07166C186.37 5.63501 187.357 5.41692 188.425 5.41692C189.857 5.41692 190.981 5.79786 191.794 6.55996Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M202.684 6.44265C203.491 7.12656 203.94 8.04171 204.031 9.18786H200.75C200.736 8.73208 200.567 8.38056 200.242 8.13282C199.916 7.88556 199.473 7.76169 198.914 7.76169C198.51 7.76169 198.181 7.83989 197.927 7.99604C197.673 8.15219 197.547 8.38056 197.547 8.67995C197.547 8.97982 197.722 9.21417 198.074 9.38323C198.426 9.55278 198.959 9.72878 199.675 9.91076C200.469 10.1327 201.118 10.3441 201.619 10.5459C202.12 10.748 202.553 11.0538 202.918 11.4644C203.282 11.8745 203.465 12.4118 203.465 13.0761C203.465 13.3497 203.438 13.5972 203.387 13.8186C203.23 14.6393 202.758 15.307 201.97 15.8213C201.183 16.3362 200.125 16.5933 198.797 16.5933C197.325 16.5933 196.143 16.2645 195.251 15.6066C194.359 14.9488 193.887 14.0209 193.835 12.8222H197.117C197.078 13.2911 197.24 13.6496 197.605 13.8968C197.969 14.1446 198.458 14.268 199.07 14.268C199.512 14.268 199.845 14.1802 200.066 14.0044C200.288 13.8284 200.398 13.5972 200.398 13.3107C200.398 12.9853 200.228 12.7409 199.89 12.578C199.551 12.4152 199.005 12.2296 198.25 12.0213C197.494 11.8257 196.869 11.6272 196.374 11.4249C195.879 11.2236 195.453 10.924 195.095 10.5265C194.737 10.1293 194.558 9.60491 194.558 8.95352C194.558 8.81028 194.584 8.56947 194.636 8.23063C194.766 7.40993 195.228 6.73582 196.023 6.2083C196.817 5.68054 197.878 5.41678 199.207 5.41678C200.717 5.41678 201.876 5.75897 202.684 6.44265Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.4153 5.55373L32.4817 16.4564H29.1414L31.0555 5.55373H34.4153Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.1888 0.965986L34.7093 3.70833H31.3691L31.8488 0.965986H35.1888Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148.893 5.55373L146.959 16.4564H143.619L145.533 5.55373H148.893Z"
          fill="#25358E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.666 0.965986L149.186 3.70833H145.846L146.326 0.965986H149.666Z"
          fill="#25358E"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="209" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
