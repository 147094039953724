import React from 'react'

export default function SvgFooterBrand({ fill }) {
  return (
    <svg
      width='226'
      height='70'
      viewBox='0 0 226 70'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.2109 40.1402C45.6053 42.69 42.7641 44.3805 39.524 44.3805H1.41339V54.0589H34.071C42.3783 54.0589 49.38 48.4799 51.5378 40.8656C50.4466 40.3992 49.2461 40.1402 47.9853 40.1402H47.2109Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48.3568 23.4969C45.0335 19.2627 39.8719 16.5406 34.0715 16.5406H1.41392V26.2251H39.5237C41.7427 26.2251 43.7744 27.0257 45.3503 28.3522C46.8247 27.1202 47.9009 25.4297 48.3568 23.4969Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.3591 31.7842C51.173 28.9588 52.2247 25.597 52.2247 21.9894V20.7802C52.2247 10.7544 44.0977 2.62478 34.0711 2.62478H1.41348V12.3102H39.5241C44.537 12.3102 48.6014 16.392 48.6014 21.4058C48.6014 22.1259 48.516 22.8249 48.3568 23.4969C47.9009 25.4297 46.8247 27.1202 45.3503 28.3522C43.8654 29.593 41.9776 30.3693 39.91 30.4559H1.41348V40.143H34.0711C34.149 40.143 34.2251 40.1413 34.3021 40.1404L47.2109 40.1402H47.9853C49.2461 40.1402 50.4466 40.3992 51.5378 40.8656C54.7858 42.2534 57.0635 45.4832 57.0635 49.236C57.0635 54.2498 52.9991 58.2958 47.9854 58.2958H1.41348V67.9742H42.5332C52.559 67.9742 60.6869 59.8463 60.6869 49.8205V48.6113C60.6869 40.9997 56.0021 34.4818 49.3591 31.7842Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.3249 64.7483C63.2295 64.7115 63.093 64.6923 62.918 64.6923H62.7474V65.4702H63.0195C63.184 65.4702 63.3118 65.4378 63.4045 65.3722C63.4955 65.3065 63.5419 65.2007 63.5419 65.0537C63.5419 64.9067 63.4693 64.8052 63.3249 64.7483ZM62.0456 66.9008V64.2198C62.2119 64.2198 62.4604 64.2207 62.792 64.2215C63.1236 64.2224 63.3074 64.2242 63.3441 64.2268C63.5576 64.2417 63.7335 64.288 63.8735 64.3642C64.1115 64.4954 64.2305 64.7089 64.2305 65.0038C64.2305 65.2295 64.1684 65.3923 64.0424 65.4929C63.9164 65.5927 63.7615 65.653 63.5778 65.6723C63.7458 65.7073 63.8735 65.7589 63.9593 65.8272C64.1159 65.9549 64.1946 66.1553 64.1946 66.4283V66.668C64.1946 66.6943 64.1964 66.7205 64.1999 66.7468C64.2034 66.773 64.2104 66.7993 64.2191 66.8255L64.2445 66.9008H63.5743C63.5533 66.8159 63.5375 66.6925 63.5296 66.5307C63.5226 66.3688 63.5078 66.2594 63.4868 66.2025C63.4518 66.108 63.3853 66.0424 63.289 66.0057C63.2365 65.9838 63.1569 65.9698 63.0501 65.9628L62.8953 65.9523H62.7474V66.9008H62.0456ZM61.6843 64.1542C61.2993 64.5479 61.1068 65.0152 61.1068 65.5559C61.1068 66.1159 61.3019 66.5919 61.6948 66.983C62.0833 67.375 62.554 67.571 63.1053 67.571C63.6548 67.571 64.1238 67.3733 64.5131 66.9804C64.9025 66.584 65.0968 66.1089 65.0968 65.5559C65.0968 65.0178 64.9043 64.5505 64.5166 64.1542C64.1246 63.7517 63.6548 63.5504 63.1053 63.5504C62.5496 63.5504 62.0754 63.7517 61.6843 64.1542ZM64.7993 67.2919C64.3276 67.7469 63.7641 67.9744 63.1053 67.9744C62.4254 67.9744 61.8514 67.7417 61.3859 67.2753C60.9195 66.8089 60.6859 66.2358 60.6859 65.5559C60.6859 64.8489 60.9379 64.2618 61.4419 63.7937C61.9144 63.3553 62.4683 63.1365 63.1053 63.1365C63.7729 63.1365 64.3425 63.3728 64.815 63.8462C65.2875 64.3187 65.5246 64.8883 65.5246 65.5559C65.5246 66.2428 65.2823 66.8212 64.7993 67.2919Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M104.087 0.13959V13.1132H100.089V10.7651C99.7172 11.5245 99.163 12.1292 98.427 12.5785C97.6907 13.0281 96.8346 13.2526 95.8582 13.2526C94.3706 13.2526 93.1885 12.7569 92.3134 11.7647C91.4377 10.7728 91.0001 9.40861 91.0001 7.67284V0.13959H94.9518V7.18455C94.9518 8.0678 95.1803 8.75357 95.6375 9.24215C96.0944 9.73044 96.7106 9.97459 97.4856 9.97459C98.291 9.97459 98.9269 9.71878 99.3917 9.20715C99.8566 8.69552 100.089 7.96735 100.089 7.02179V0.13959H104.087Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M118.837 1.48817C119.712 2.48012 120.15 3.84427 120.15 5.58033V13.1133H116.175V6.04528C116.175 5.1774 115.946 4.49902 115.489 4.01073C115.032 3.52244 114.424 3.27858 113.665 3.27858C112.859 3.27858 112.219 3.53411 111.747 4.04545C111.274 4.55707 111.038 5.2861 111.038 6.2311V13.1133H107.063V0.139675H111.038V2.51114C111.41 1.73602 111.964 1.12423 112.7 0.67435C113.436 0.225041 114.3 0.000244141 115.292 0.000244141C116.78 0.000244141 117.961 0.49622 118.837 1.48817Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M94.3241 20.6455C94.8507 20.1728 95.5096 19.9361 96.2999 19.9361C96.9199 19.9361 97.4121 20.0951 97.7762 20.413C98.1401 20.7305 98.3225 21.1451 98.3225 21.6568C98.3225 22.3388 98.0627 22.9159 97.5438 23.3888C97.0243 23.8618 96.3697 24.0979 95.5796 24.0979C94.9437 24.0979 94.4439 23.9392 94.0803 23.6213C93.7158 23.304 93.5338 22.8971 93.5338 22.4009C93.5338 21.7031 93.7972 21.1184 94.3241 20.6455ZM97.2764 25.6789L94.9753 38.6525H91L93.278 25.6789H97.2764Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M111.445 26.6208C112.15 27.3413 112.502 28.3605 112.502 29.678C112.502 30.1276 112.456 30.6082 112.363 31.1199L111.015 38.6525H107.063L108.295 31.5845C108.341 31.3213 108.365 31.0812 108.365 30.8638C108.365 30.2127 108.179 29.7093 107.807 29.3528C107.435 28.9962 106.915 28.8181 106.249 28.8181C105.459 28.8181 104.781 29.0736 104.215 29.585C103.649 30.0966 103.289 30.8253 103.134 31.7706L101.926 38.6525H97.9272L100.205 25.6789H104.204L103.785 28.004C104.297 27.2448 104.959 26.6438 105.773 26.2019C106.586 25.7603 107.489 25.5395 108.481 25.5395C109.751 25.5395 110.739 25.8997 111.445 26.6208Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M119.662 29.8406C119.042 30.3989 118.647 31.1743 118.477 32.1657C118.43 32.4449 118.407 32.7087 118.407 32.9565C118.407 33.7003 118.62 34.2814 119.046 34.7002C119.472 35.1188 120.042 35.328 120.755 35.328C121.592 35.328 122.328 35.0411 122.963 34.4678C123.598 33.8944 124.001 33.1272 124.172 32.1657C124.218 31.9025 124.242 31.6544 124.242 31.4219C124.242 30.6627 124.024 30.0697 123.591 29.6432C123.157 29.2172 122.583 29.0041 121.87 29.0041C121.018 29.0041 120.282 29.2829 119.662 29.8406ZM115.524 37.4206C114.687 36.4904 114.269 35.2119 114.269 33.5839C114.269 32.9952 114.323 32.4065 114.432 31.8169C114.664 30.5307 115.102 29.4107 115.745 28.4574C116.388 27.5042 117.171 26.7757 118.093 26.2718C119.015 25.7681 119.995 25.5163 121.034 25.5163C122.087 25.5163 122.947 25.7414 123.614 26.1907C124.28 26.6403 124.707 27.2524 124.892 28.0272L125.311 25.6791H129.263L126.985 38.583C126.783 39.792 126.369 40.8807 125.741 41.8496C125.113 42.8183 124.28 43.5894 123.242 44.163C122.204 44.7364 120.995 45.0233 119.616 45.0233C117.694 45.0233 116.213 44.562 115.176 43.64C114.137 42.7175 113.595 41.4661 113.548 39.8851H117.477C117.523 40.4118 117.74 40.8227 118.128 41.1175C118.515 41.4114 119.065 41.5591 119.778 41.5591C120.6 41.5591 121.301 41.3226 121.882 40.8497C122.463 40.3768 122.847 39.6213 123.033 38.583L123.451 36.3046C122.986 37.0797 122.355 37.6917 121.557 38.1413C120.758 38.5909 119.856 38.8154 118.848 38.8154C117.469 38.8154 116.361 38.3502 115.524 37.4206Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M136.887 21.4474L133.865 38.6527H129.867L132.889 21.4474H136.887Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M148.278 22.3542L142.257 24.749L142.722 22.052L148.812 19.355L148.278 22.3542ZM146.278 30.5152C146.278 29.8954 146.084 29.4304 145.697 29.12C145.31 28.8101 144.822 28.6553 144.233 28.6553C143.504 28.6553 142.861 28.8721 142.303 29.3061C141.745 29.7403 141.358 30.3754 141.141 31.2126H146.209C146.255 30.9491 146.278 30.7169 146.278 30.5152ZM148.8 26.8766C149.769 27.7832 150.253 28.9962 150.253 30.5152C150.253 30.872 150.215 31.3056 150.137 31.817C150.075 32.1892 149.975 32.5457 149.835 32.8869H140.839C140.808 33.1968 140.792 33.4062 140.792 33.5146C140.792 34.9252 141.467 35.63 142.815 35.63C143.388 35.63 143.899 35.4832 144.349 35.1886C144.798 34.8941 145.124 34.514 145.325 34.0493H149.533C149.021 35.4598 148.134 36.6069 146.871 37.4901C145.608 38.3736 144.132 38.8156 142.443 38.8156C140.769 38.8156 139.44 38.3503 138.456 37.4204C137.472 36.4905 136.98 35.204 136.98 33.561C136.98 33.065 137.026 32.5378 137.119 31.9797C137.352 30.6626 137.832 29.5152 138.561 28.5386C139.289 27.5623 140.192 26.8148 141.269 26.2952C142.346 25.7759 143.52 25.5161 144.791 25.5161C146.495 25.5161 147.832 25.9697 148.8 26.8766Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M161.876 26.7369C162.837 27.5505 163.372 28.6397 163.481 30.0033H159.575C159.559 29.461 159.358 29.0427 158.971 28.7482C158.583 28.4537 158.056 28.3065 157.39 28.3065C156.909 28.3065 156.518 28.3993 156.216 28.5854C155.914 28.7712 155.763 29.0427 155.763 29.3989C155.763 29.7558 155.972 30.0349 156.39 30.2361C156.809 30.4378 157.444 30.647 158.296 30.8638C159.242 31.1279 160.013 31.3791 160.609 31.6193C161.206 31.86 161.721 32.224 162.155 32.7123C162.589 33.2006 162.806 33.84 162.806 34.6304C162.806 34.956 162.775 35.2505 162.713 35.514C162.527 36.4903 161.965 37.2847 161.028 37.8971C160.09 38.5095 158.831 38.8154 157.25 38.8154C155.499 38.8154 154.093 38.4241 153.031 37.6413C151.97 36.8588 151.408 35.7544 151.346 34.3282H155.251C155.205 34.8863 155.398 35.3128 155.832 35.607C156.266 35.9015 156.847 36.0487 157.576 36.0487C158.102 36.0487 158.498 35.9439 158.761 35.7348C159.025 35.5256 159.156 35.2505 159.156 34.9096C159.156 34.522 158.955 34.2315 158.552 34.0374C158.149 33.8439 157.498 33.6228 156.599 33.375C155.701 33.1425 154.957 32.9063 154.368 32.6659C153.779 32.4257 153.271 32.0692 152.845 31.5963C152.419 31.1236 152.206 30.4999 152.206 29.7245C152.206 29.554 152.237 29.2678 152.299 28.8645C152.454 27.8877 153.004 27.0858 153.95 26.4581C154.895 25.8298 156.158 25.5162 157.739 25.5162C159.536 25.5162 160.915 25.9231 161.876 26.7369Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M111.096 51.8424C112.003 52.811 112.456 54.1868 112.456 55.9692V63.4558H109.202V56.3414C109.202 55.3495 108.95 54.586 108.446 54.0513C107.943 53.5164 107.249 53.2489 106.366 53.2489C105.451 53.2489 104.723 53.536 104.18 54.1094C103.638 54.6828 103.367 55.5199 103.367 56.6203V63.4558H100.113V56.3414C100.113 55.3495 99.8605 54.586 99.3569 54.0513C98.8534 53.5164 98.1598 53.2489 97.2764 53.2489C96.3617 53.2489 95.6337 53.536 95.0914 54.1094C94.5486 54.6828 94.2778 55.5199 94.2778 56.6203V63.4558H91V50.5752H94.2778V53.0164C94.6186 52.1796 95.1571 51.5325 95.8932 51.0752C96.6292 50.6179 97.4858 50.3894 98.4619 50.3894C99.5 50.3894 100.403 50.6373 101.17 51.1333C101.937 51.6295 102.514 52.3423 102.902 53.2722C103.305 52.389 103.905 51.6876 104.704 51.1683C105.502 50.6489 106.397 50.3894 107.388 50.3894C108.954 50.3894 110.19 50.874 111.096 51.8424Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M127.775 50.5752V63.4561H124.497V60.8518C124.156 61.7203 123.594 62.3981 122.812 62.8864C122.029 63.3744 121.127 63.6188 120.104 63.6188C118.616 63.6188 117.431 63.1305 116.547 62.1537C115.664 61.1774 115.222 59.8138 115.222 58.0618V50.5752H118.477V57.6899C118.477 58.7131 118.74 59.5073 119.267 60.073C119.794 60.639 120.507 60.9216 121.406 60.9216C122.351 60.9216 123.103 60.6194 123.661 60.015C124.219 59.4103 124.497 58.5424 124.497 57.4107V50.5752H127.775Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M133.075 50.5752L136.585 59.3175L139.84 50.5752H143.443L135.562 69.5942H131.936L134.842 63.0839L129.425 50.5752H133.075Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M168.909 51.4239C169.613 52.1446 169.966 53.1639 169.966 54.4814C169.966 54.931 169.92 55.4113 169.827 55.9229L168.478 63.4559H164.526L165.759 56.3879C165.805 56.1247 165.829 55.8842 165.829 55.6668C165.829 55.0161 165.642 54.5124 165.271 54.1559C164.898 53.7996 164.379 53.6212 163.713 53.6212C162.923 53.6212 162.244 53.8767 161.679 54.3883C161.113 54.9 160.753 55.6284 160.598 56.574L159.389 63.4559H155.391L157.669 50.4823H161.667L161.249 52.8071C161.76 52.0479 162.423 51.4469 163.236 51.0053C164.05 50.5637 164.953 50.3428 165.945 50.3428C167.215 50.3428 168.203 50.7031 168.909 51.4239Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M177.01 54.644C176.389 55.202 175.994 55.9774 175.824 56.9691C175.778 57.2479 175.754 57.5117 175.754 57.7595C175.754 58.5034 175.967 59.0847 176.394 59.5033C176.82 59.9219 177.389 60.131 178.102 60.131C178.939 60.131 179.675 59.8445 180.311 59.2708C180.946 58.6974 181.349 57.9303 181.52 56.9691C181.566 56.7056 181.589 56.4577 181.589 56.2252C181.589 55.4658 181.372 54.873 180.938 54.4465C180.504 54.0205 179.931 53.8071 179.218 53.8071C178.366 53.8071 177.629 54.086 177.01 54.644ZM172.872 62.2236C172.035 61.2937 171.617 60.0149 171.617 58.3873C171.617 57.7985 171.671 57.2098 171.779 56.6202C172.012 55.334 172.45 54.2137 173.093 53.2608C173.735 52.3075 174.518 51.5791 175.44 51.0751C176.363 50.5715 177.343 50.3196 178.381 50.3196C179.435 50.3196 180.295 50.5444 180.962 50.9937C181.628 51.4436 182.054 52.0557 182.24 52.8305L182.658 50.4824H186.61L184.332 63.3863C184.131 64.5954 183.716 65.6841 183.089 66.6527C182.461 67.6213 181.628 68.3924 180.59 68.9664C179.551 69.5398 178.342 69.8263 176.963 69.8263C175.041 69.8263 173.561 69.365 172.523 68.4431C171.485 67.5208 170.943 66.2691 170.896 64.6884H174.825C174.871 65.2148 175.088 65.626 175.475 65.9205C175.863 66.2148 176.413 66.3622 177.126 66.3622C177.947 66.3622 178.649 66.126 179.23 65.653C179.811 65.1801 180.194 64.4243 180.381 63.3863L180.799 61.1079C180.334 61.8827 179.702 62.4951 178.904 62.9447C178.106 63.3943 177.203 63.6188 176.196 63.6188C174.817 63.6188 173.708 63.1535 172.872 62.2236Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M194.118 46.2508L191.096 63.456H187.098L190.12 46.2508H194.118Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M205.392 47.1572L199.372 49.552L199.837 46.855L205.927 44.1583L205.392 47.1572ZM203.393 55.3185C203.393 54.6984 203.199 54.2335 202.812 53.923C202.424 53.6134 201.937 53.4584 201.348 53.4584C200.619 53.4584 199.976 53.6755 199.418 54.1094C198.86 54.5434 198.473 55.1788 198.256 56.0159H203.324C203.37 55.7524 203.393 55.5199 203.393 55.3185ZM205.915 51.6796C206.884 52.5865 207.368 53.7995 207.368 55.3185C207.368 55.675 207.33 56.1087 207.252 56.6203C207.19 56.9922 207.09 57.3491 206.95 57.6899H197.954C197.922 57.9998 197.907 58.2093 197.907 58.3177C197.907 59.7282 198.581 60.4333 199.93 60.4333C200.503 60.4333 201.014 60.2865 201.464 59.9917C201.913 59.6972 202.239 59.3173 202.44 58.8526H206.648C206.136 60.2632 205.249 61.4099 203.986 62.2932C202.723 63.177 201.247 63.6189 199.557 63.6189C197.884 63.6189 196.555 63.1536 195.571 62.2234C194.587 61.2938 194.095 60.0073 194.095 58.3641C194.095 57.8684 194.141 57.3411 194.234 56.7831C194.467 55.4656 194.947 54.3186 195.676 53.342C196.404 52.3657 197.306 51.6179 198.384 51.0986C199.46 50.579 200.634 50.3195 201.905 50.3195C203.61 50.3195 204.947 50.7728 205.915 51.6796Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M218.875 51.54C219.836 52.3538 220.371 53.4428 220.479 54.8067H216.574C216.558 54.2643 216.356 53.846 215.969 53.5512C215.582 53.257 215.055 53.1096 214.388 53.1096C213.908 53.1096 213.517 53.2027 213.215 53.3885C212.912 53.5743 212.762 53.846 212.762 54.2023C212.762 54.5588 212.97 54.8383 213.389 55.0395C213.807 55.2409 214.443 55.4501 215.295 55.6669C216.24 55.931 217.011 56.1825 217.608 56.4227C218.205 56.6631 218.72 57.0271 219.154 57.5153C219.588 58.0036 219.805 58.6433 219.805 59.4335C219.805 59.759 219.774 60.0538 219.712 60.3171C219.526 61.2936 218.964 62.0881 218.027 62.7005C217.089 63.3128 215.83 63.6187 214.249 63.6187C212.498 63.6187 211.091 63.2272 210.03 62.4444C208.968 61.6618 208.406 60.5575 208.344 59.1316H212.25C212.203 59.6893 212.397 60.1159 212.831 60.4101C213.265 60.7046 213.846 60.852 214.574 60.852C215.101 60.852 215.496 60.7473 215.76 60.5382C216.024 60.3287 216.155 60.0538 216.155 59.7127C216.155 59.3254 215.953 59.0349 215.551 58.8408C215.147 58.6473 214.497 58.4262 213.598 58.1781C212.699 57.9456 211.956 57.7097 211.366 57.469C210.777 57.2288 210.27 56.8725 209.844 56.3993C209.418 55.927 209.205 55.3032 209.205 54.5278C209.205 54.3574 209.235 54.0708 209.298 53.6676C209.452 52.691 210.002 51.8889 210.948 51.2612C211.893 50.6331 213.156 50.3196 214.737 50.3196C216.535 50.3196 217.914 50.7265 218.875 51.54Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M154.857 50.4824L152.555 63.456H148.58L150.858 50.4824H154.857Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M155.777 45.0231L155.206 48.2866H151.231L151.802 45.0231H155.777Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M223.281 65.1702C223.179 65.1304 223.035 65.1107 222.849 65.1107H222.668V65.9368H222.957C223.131 65.9368 223.267 65.9021 223.365 65.8324C223.463 65.7627 223.511 65.6503 223.511 65.4943C223.511 65.3387 223.434 65.2305 223.281 65.1702ZM221.922 67.4569V64.6088C222.098 64.6088 222.363 64.6094 222.715 64.6105C223.067 64.6116 223.263 64.6136 223.302 64.6159C223.527 64.6321 223.714 64.6808 223.863 64.7622C224.117 64.9019 224.243 65.1284 224.243 65.442C224.243 65.6813 224.176 65.8543 224.043 65.9604C223.909 66.0668 223.745 66.1306 223.549 66.1514C223.728 66.1886 223.863 66.2436 223.954 66.3158C224.121 66.4516 224.205 66.6644 224.205 66.9547V67.2093C224.205 67.2372 224.207 67.2651 224.21 67.293C224.214 67.3212 224.221 67.3488 224.231 67.3766L224.257 67.4569H223.546C223.523 67.3664 223.507 67.2349 223.499 67.0628C223.491 66.8909 223.475 66.7748 223.452 66.7145C223.415 66.6143 223.345 66.5446 223.243 66.5051C223.187 66.482 223.102 66.4669 222.989 66.4598L222.825 66.4493H222.668V67.4569H221.922ZM221.538 64.5391C221.13 64.9574 220.925 65.4536 220.925 66.0279C220.925 66.6226 221.133 67.1282 221.549 67.544C221.962 67.9603 222.462 68.168 223.048 68.168C223.631 68.168 224.129 67.9588 224.543 67.5405C224.956 67.12 225.163 66.6155 225.163 66.0279C225.163 65.4559 224.958 64.9599 224.546 64.5391C224.13 64.1114 223.631 63.8977 223.048 63.8977C222.457 63.8977 221.954 64.1114 221.538 64.5391ZM224.846 67.8718C224.346 68.3549 223.747 68.5968 223.048 68.5968C222.325 68.5968 221.716 68.3492 221.222 67.8544C220.727 67.3593 220.479 66.7506 220.479 66.0279C220.479 65.2769 220.746 64.6532 221.281 64.1558C221.782 63.6908 222.371 63.4583 223.048 63.4583C223.756 63.4583 224.362 63.7093 224.863 64.2116C225.365 64.7135 225.616 65.3188 225.616 66.0279C225.616 66.7575 225.36 67.3721 224.846 67.8718Z'
        fill={fill}
      />
    </svg>
  )
}
