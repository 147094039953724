import React from "react";

export default function SvgEmail({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2765 12.5697L11.6971 13.7379C11.8653 13.8775 12.1346 13.8775 12.3029 13.7379L13.7237 12.5697L19.2982 17.0769H4.702L10.2765 12.5697ZM20.0771 7.48564V16.4783L14.4735 11.9568L20.0771 7.48564ZM3.92315 7.48564L9.52648 11.9568L3.92315 16.4783V7.48564ZM4.73085 6.92302H19.2694L12.0001 12.7645L4.73085 6.92302ZM3.41823 5.99994C3.19138 6.0214 2.99892 6.23371 3.00007 6.46148V17.5385C3.00007 17.7801 3.21976 18 3.46161 18H20.5386C20.7802 18 21.0001 17.7801 21.0001 17.5385V6.46148C21.0001 6.21986 20.7802 5.99994 20.5386 5.99994H3.41823Z"
        fill={fill}
      />
    </svg>
  );
}
